import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import useStyles from "../../estilos";
import { Parallax } from "rc-scroll-anim";

export default function ParaQueEs() {
  const classes = useStyles();

  return (
    <Box my={5} textAlign="center" id="cafi">
      <Parallax
        animation={{ x: 0, opacity: 1, playScale: [0.1, 0.6] }}
        style={{ transform: "translateX(-100px)", opacity: 0 }}
      >
        <Typography component="div" variant="h3" color="primary">
          ¿Qué es CAFI?
        </Typography>
        <Box my={2} display="flex" justifyContent="center" alignItems="center">
          <Divider className={classes.divisorPrincipal} />
        </Box>
        <Typography component="div" variant="h5" style={{ color: "black" }}>
          <Box textAlign="center" mt={1}>
            CAFI es un Punto de Venta que te permite llevar el control de las
            ventas, finanzas e inventarios de tu negocio. Nuestro sistema
            funciona a través de la nube y de manera remota, lo cual como
            administrador te permite acceder a toda tu información desde
            cualquier dispositivo y lugar con acceso a internet.
          </Box>
        </Typography>
        <br />
        <Typography component="div" variant="h4" color="primary">
          <Box textAlign="center" mt={1}>
            ¿Qué nos diferencia de otros puntos de venta?
          </Box>
        </Typography>
        <Typography component="div" variant="h5" style={{ color: "black" }}>
          <Box textAlign="center" mt={1}>
            Con CAFI, puedes consultar y exportar tus reportes en Excel y PDF.
          </Box>
        </Typography>
      </Parallax>
    </Box>
  );
}
