import React from "react";
import BannerPrincipal from "../BannerPrincipal/bannerPrincipal";
import SeecionPrincipal from "../SeccionPrincipal";
import Awesome from "./awesome";
import Paquetes from "../Paquetes/paquetes";
import Contacto from "../Contacto/contacto";
import { Toolbar } from "@mui/material";
import CarruselScreens from "../Carousel";
import BotonesFlotantes from "./BotonesFlotantes";
//import Catalogo from "../Catalogo";

export default function Home() {
  return (
    <>
      <Toolbar variant="dense" />
      <BannerPrincipal />
      <SeecionPrincipal />
      <Awesome />
      <CarruselScreens />
      <Paquetes />
      {/* <Catalogo /> */}
      <Contacto />
      <BotonesFlotantes />
    </>
  );
}
