import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Celular from "../../../image/Publicidad CAFI 2.jpg";
import useStyles from "../../estilos";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Parallax } from "rc-scroll-anim";
import TodosLosUsos from "./TodosLosUsos";
import { lista1, lista2 } from "./ListaUsos";

export default function ParaQueSirve() {
  const classes = useStyles();

  return (
    <>
      <Parallax
        animation={{ x: 0, opacity: 1, playScale: [0.1, 0.8] }}
        style={{ transform: "translateX(0px)", opacity: 0 }}
      >
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "flex-end" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <List>
                {lista1.map(({ uso, icon }, index) => (
                  <ListItem key={uso} disablePadding>
                    <ListItemText
                      primary={
                        <Typography variant="h4" align="right">
                          {uso}
                        </Typography>
                      }
                    />
                    <ListItemIcon sx={{ pr: 0, pl: 3 }}>{icon}</ListItemIcon>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              className={classes.containerImgSec}
            >
              <img className={classes.imagen} src={Celular} alt="Celular" />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "flex-start" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div>
                {lista2.map(({ uso, icon }, index) => (
                  <ListItem key={uso} disablePadding>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText
                      primary={<Typography variant="h4">{uso}</Typography>}
                    />
                  </ListItem>
                ))}
              </div>
            </Box>
          </Grid>
        </Grid>
      </Parallax>
      <Box sx={{ mb: 2, display: "flex", justifyContent: "center" }}>
        <TodosLosUsos />
      </Box>
    </>
  );
}
