import React from "react";
import "react-multi-carousel/lib/styles.css";
import { Box, Divider, Typography } from "@mui/material";
import useStyles from "../estilos";
import CarouselXs from "./carouselXs";
import CarouselLg from "./carouselLg";

export default function CarruselScreens() {
  const classes = useStyles();

  return (
    <Box >
      <Box id="galeria">
        <Box textAlign="center" py={3}>
          <Typography component="div" variant="h3" color="primary">
            Sistema fácil e intuitivo
          </Typography>
          <Box display="flex" justifyContent="center" mt={1}>
            <Divider className={classes.divisorPrincipal} />
          </Box>
          <br />
          <Typography component="div" variant="h6">
            Tenemos justo lo que tu negocio necesita
          </Typography>
        </Box>
      </Box>
      <Box sx={{display: {xs: "none", sm: "block"}}}>
        <CarouselLg />
      </Box>
      <Box sx={{display: {xs: "block", sm: "none"}}}>
        <CarouselXs />
      </Box>
    </Box>
  );
}
