import React from "react";
import "./banner.css";
import "rc-banner-anim/assets/index.css";
import Carrusel2 from "../../image/CarruselDos.jpg";
import Carrusel3 from "../../image/CarruselTres.jpg";
import { Box, Button, Grid, Typography } from "@mui/material";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { makeStyles } from "@mui/styles";

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function BannerPrincipal() {
  const slides = [
    {
      text: "Adquiere CAFI",
      subtext: "Punto de Venta",
      background: Carrusel2,
      buttonText: "¡Cotiza ahora!",
    },
    {
      text: "Manténte a la vanguardia",
      subsubtext: "con CAFI",
      background: Carrusel3,
      buttonText: "¡Adquiérelo ya!",
    },
  ];

  const stylesLocal = makeStyles((theme) => ({
    contenedor: {
      height: { xs: "40vh", md: "70vh" },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    images: {
      width: "100%",
      height: "auto",
    },
    slide2: {
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "flex-end",
      alignItems: "center",
    },
  }));

  const estilo = stylesLocal();

  return (
    <Box id="inicio" className={estilo.contenedor}>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation
        pagination={{
          clickable: true,
        }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <Box
              sx={{
                backgroundImage: `url('${slide.background}')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: { xs: "85vh", md: "90vh" },
              }}
            >
              <Grid
                container
                sx={
                  index === 0
                    ? { flexDirection: "row" }
                    : { flexDirection: "row-reverse" }
                }
              >
                <Grid
                  item
                  xs={6}
                  justifyContent="center"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    placeItems: "center",
                    height: { xs: "85vh", md: "90vh" },
                    p: 1,
                  }}
                >
                  <Typography
                    variant="h1"
                    align="center"
                    sx={
                      index === 0
                        ? {
                            fontSize: { xs: "30px", sm: "50px", md: "80px" },
                            color: "#fff",
                          }
                        : {
                            fontSize: { xs: "30px", sm: "50px", md: "80px" },
                            color: "#000",
                          }
                    }
                  >
                    {slide.text}
                  </Typography>
                  <Typography
                    align="center"
                    variant="h1"
                    sx={
                      index === 0
                        ? {
                            fontSize: { xs: "30px", sm: "50px", md: "80px" },
                            color: "#fff",
                          }
                        : {
                            fontSize: { xs: "30px", sm: "50px", md: "80px" },
                            color: "#000",
                          }
                    }
                  >
                    {slide.subtext}
                  </Typography>
                  <Typography
                    align="center"
                    variant="h1"
                    sx={{ fontSize: { xs: "30px", sm: "50px", md: "80px" } }}
                    color="primary"
                  >
                    {slide.subsubtext}
                  </Typography>
                  <Button
                    variant="contained"
                    href="https://wa.me/523173832252?text=Hola, me gustaría tener más información"
                    target="_blank"
                    sx={{
                      padding: "16px 24px",
                      fontSize: { xs: "12px", sm: "18px", md: "24px" },
                      fontWeight: "bold",
                      color: "#fff",
                      border: "none",
                      borderRadius: "50px",
                      cursor: "pointer",
                      transition: "all 0.3s ease-in-out",
                      mt: "10px",
                    }}
                  >
                    {slide.buttonText}
                  </Button>
                </Grid>
                <Grid item md={6}></Grid>
              </Grid>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}
