import React from 'react'
// import { Link } from 'react-router-dom';


function Galeria() {
    return (
        <div>
            
        </div>
    )
}


export default Galeria;