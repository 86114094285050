import { Facebook, Instagram, WhatsApp, YouTube } from "@mui/icons-material";
import { Box, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

function BotonesFlotantes() {
  const stylesLocal = makeStyles((theme) => ({
    socialIconsContainer: {
      marginRight: "10px",
      position: "fixed",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: theme.spacing(1),
      bottom: theme.spacing(1),
      right: theme.spacing(0),
      zIndex: theme.zIndex.appBar + 1,
      //   backgroundColor: theme.palette.background.paper,

      //   boxShadow: theme.shadows[4],
      padding: theme.spacing(1),
    },
    socialIcon: {
      // fontSize: 32,
    },
  }));

  const estilo = stylesLocal();

  return (
    <Box className={estilo.socialIconsContainer}>
      <Link
        href="https://www.facebook.com/profile.php?id=100090761797800&mibextid=LQQJ4d"
        color="inherit"
        target="_blank"
      >
        <Facebook
          sx={{
            bgcolor: "#024a86",
            color: "#fff",
            borderRadius: "50%",
            fontSize: { xs: "40px", md: "50px" },
            padding: "10px",
            "&:hover": {
              bgcolor: "#003d6b",
            },
          }}
        />
      </Link>

      <Link
        href="https://www.instagram.com/cafi_pv/"
        color="inherit"
        target="_blank"
      >
        <Instagram
          sx={{
            bgcolor: "#dd2a7b",
            color: "#fff",
            borderRadius: "50%",
            fontSize: { xs: "40px", md: "50px" },
            padding: "10px",
            "&:hover": {
              bgcolor: "#c41e6e",
            },
          }}
        />
      </Link>

      <Link
        href="https://www.youtube.com/@cafipuntodeventa"
        color="inherit"
        target="_blank"
      >
        <YouTube
          sx={{
            bgcolor: "#c4302b",
            color: "#fff",
            borderRadius: "50%",
            fontSize: { xs: "40px", md: "50px" },
            padding: "10px",
            "&:hover": {
              bgcolor: "#a32a24",
            },
          }}
        />
      </Link>
      <Link
        href="https://wa.me/523173832252?text=Hola, me gustaría tener más información"
        color="inherit"
        target="_blank"
      >
        <WhatsApp
          sx={{
            bgcolor: "#128c7e",
            color: "#fff",
            borderRadius: "50%",
            fontSize: { xs: "40px", md: "50px" },
            padding: "10px",
            "&:hover": {
              bgcolor: "#0f7565",
            },
          }}
        />
      </Link>
    </Box>
  );
}

export default BotonesFlotantes;
