import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  Toolbar,
} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import ErrorIcon from "@mui/icons-material/Error";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import MailIcon from "@mui/icons-material/Mail";

import imagen from "../../image/logo_cafi_horizontal_white.png";
import { ArrowBack, Launch, Login, PointOfSale } from "@mui/icons-material";

export default function Navegacion(props) {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <AppBar elevation={0}>
        <Toolbar variant="dense">
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              edge="start"
              aria-label="show more"
              aria-haspopup="true"
              onClick={handleDrawerOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <img width={120} alt="no hay imagen" src={imagen} />
          <Box flexGrow={1} />
          <Box sx={{ display: { xs: "none", md: "flex" }, gap: 1 }}>
            <Button
              component={HashLink}
              smooth="true"
              to="/#inicio"
              color="inherit"
              style={{ fontWeight: 600, textTransform: "none" }}
            >
              Inicio
            </Button>
            <Button
              component={HashLink}
              smooth="true"
              to="/#cafi"
              color="inherit"
              style={{ fontWeight: 600, textTransform: "none" }}
            >
              ¿Qué es CAFI?
            </Button>
            <Button
              component={HashLink}
              smooth="true"
              to="/#galeria"
              color="inherit"
              style={{ fontWeight: 600, textTransform: "none" }}
            >
              Galeria
            </Button>
            <Button
              component={HashLink}
              smooth="true"
              to="/#beneficios"
              color="inherit"
              style={{ fontWeight: 600, textTransform: "none" }}
            >
              Beneficios
            </Button>
            <Button
              component={HashLink}
              smooth="true"
              to="/#paquetes"
              color="inherit"
              style={{ fontWeight: 600, textTransform: "none" }}
            >
              Paquetes
            </Button>
            <Button
              component={HashLink}
              smooth="true"
              to="/#appMovil"
              color="inherit"
              style={{ fontWeight: 600, textTransform: "none" }}
            >
              Contacto
            </Button>
            <Button
              href="https://pos.cafipuntodeventa.com"
              target="_blank"
              color="secondary"
              style={{ fontWeight: 600, textTransform: "none" }}
              variant="contained"
              startIcon={<Launch />}
            >
              Punto de venta
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={open} onClose={handleDrawerClose}>
        <Box display="flex" justifyContent="flex-end">
          <IconButton onClick={handleDrawerClose}>
            <ArrowBack />
          </IconButton>
        </Box>
        <List>
          <ListItemButton
            component={HashLink}
            smooth="true"
            to="/#inicio"
            onClick={handleDrawerClose}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              primary="Inicio"
              color="primary"
              style={{ fontWeight: 600, textTransform: "none" }}
            />
          </ListItemButton>
          <ListItemButton
            component={HashLink}
            smooth="true"
            to="/#cafi"
            onClick={handleDrawerClose}
          >
            <ListItemIcon>
              <PointOfSale />
            </ListItemIcon>
            <ListItemText
              primary="¿Qué es CAFI?"
              color="primary"
              style={{ fontWeight: 600, textTransform: "none" }}
            />
          </ListItemButton>
          <ListItemButton
            component={HashLink}
            smooth="true"
            to="/#galeria"
            onClick={handleDrawerClose}
          >
            <ListItemIcon>
              <PhotoLibraryIcon />
            </ListItemIcon>
            <ListItemText
              primary="Galeria"
              color="primary"
              style={{ fontWeight: 600, textTransform: "none" }}
            />
          </ListItemButton>
          <ListItemButton
            component={HashLink}
            smooth="true"
            to="/#beneficios"
            onClick={handleDrawerClose}
          >
            <ListItemIcon>
              <ErrorIcon />
            </ListItemIcon>
            <ListItemText
              primary="Beneficios"
              color="primary"
              style={{ fontWeight: 600, textTransform: "none" }}
            />
          </ListItemButton>
          <ListItemButton
            component={HashLink}
            smooth="true"
            to="/#paquetes"
            onClick={handleDrawerClose}
          >
            <ListItemIcon>
              <LocalOfferIcon />
            </ListItemIcon>
            <ListItemText
              primary="Paquetes"
              color="primary"
              style={{ fontWeight: 600, textTransform: "none" }}
            />
          </ListItemButton>
          <ListItemButton
            component={HashLink}
            smooth="true"
            to="/#appMovil"
            onClick={handleDrawerClose}
          >
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText
              primary="Contacto"
              color="primary"
              style={{ fontWeight: 600, textTransform: "none" }}
            />
          </ListItemButton>
          <ListItemButton
            component={HashLink}
            smooth="true"
            to="/#catalogo"
            onClick={handleDrawerClose}
          >
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText
              primary="Catalogo"
              color="primary"
              style={{ fontWeight: 600, textTransform: "none" }}
            />
          </ListItemButton>
          <ListItemButton
            href="https://admin.cafipuntodeventa.com"
            target="_blank"
            onClick={handleDrawerClose}
          >
            <ListItemIcon>
              <Login />
            </ListItemIcon>
            <ListItemText
              primary="Entrar"
              color="primary"
              style={{ fontWeight: 600, textTransform: "none" }}
            />
          </ListItemButton>
        </List>
      </Drawer>
    </div>
  );
}
