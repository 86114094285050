import React from "react";
import { Box, Container } from "@mui/material";
import BeneficiosSegundo from "./Secciones/BeneficiosSegundo";
import Beneficios from "./Secciones/Beneficios";
//import Cuarta from "./Secciones/cuarta";
import { Parallax } from "rc-scroll-anim";
import ParaQueEs from "./SeccionQueEs/QueEs";
import ParaQueSirve from "./SeccionQueEs/ParaQueSirve";

export default function FrenteScroll() {
  return (
    <Container maxWidth="lg">
      <ParaQueEs />
      <ParaQueSirve />
      <Box
        height="auto"
        textAlign="center"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Parallax
          animation={{ x: 0, opacity: 1, playScale: [0.1, 0.8] }}
          style={{ transform: "translateX(-100px)", opacity: 0 }}
        >
          <Box my={5}>
            <Beneficios />
          </Box>
        </Parallax>
      </Box>
      <Box
        height="auto"
        textAlign="center"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Parallax
          animation={{ x: 0, opacity: 1, playScale: [0.1, 0.8] }}
          style={{ transform: "translateX(0px)", opacity: 0 }}
        >
          <Box my={5}>
            <BeneficiosSegundo />
          </Box>
        </Parallax>
      </Box>
      {/* <Box
        height="auto"
        textAlign="center"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Parallax
          animation={{ x: 0, opacity: 1, playScale: [0.1, 0.8] }}
          style={{ transform: "translateX(-100px)", opacity: 0 }}
        >
          <Box my={5}>
            <Cuarta />
          </Box>
        </Parallax>
      </Box> */}
    </Container>
  );
}
