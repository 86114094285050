import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import LaptopIcon from "@mui/icons-material/Laptop";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SecurityIcon from "@mui/icons-material/Security";
import { makeStyles } from "@mui/styles";

const stylesLocal = makeStyles((theme) => ({
  large: {
    fontSize: "70px!important",
    color: "white",
  },
  colorLetra: {
    color: "white",
  },
}));

export default function Awesome() {
  const local = stylesLocal();
  return (
    <Box
      sx={{ py: { xs: 25, sm: 20 } }}
      className="poligonos"
      style={{ background: "#953ee4" }}
      id="beneficios"
    >
      {/* Secciones de titulos */}
      <Box sx={{ pb: 5, display: "flex", justifyContent: "center" }}>
        <Typography className={local.colorLetra} variant="h3">
          ¿Por qué elegir un sistema en la nube?
        </Typography>
      </Box>
      {/* Secciones de regalos */}
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12} sm={6} md={3}>
          <Box textAlign="center">
            <LaptopIcon className={local.large} />
            <Typography className={local.colorLetra} variant="h5">
              <b>Monitorea tu información</b>
            </Typography>
            <Typography className={local.colorLetra} variant="h5">
              Acceso a toda tu información desde cualquier dispositivo con
              conexión a internet.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box textAlign="center">
            <CloudDoneIcon className={local.large} />
            <Typography className={local.colorLetra} variant="h5">
              <b>Actualiza tus datos</b>
            </Typography>
            <Typography className={local.colorLetra} variant="h5">
              Agrega productos, clientes o crea promociones sin estar
              directamente en tu empresa.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box textAlign="center">
            <MonetizationOnIcon className={local.large} />
            <Typography className={local.colorLetra} variant="h5">
              <b>Reduce costos operativos</b>
            </Typography>
            <Typography className={local.colorLetra} variant="h5">
              Ahorra costos operativos, controla cortes de caja, reportes y más
              a distancia.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box textAlign="center">
            <SecurityIcon className={local.large} />
            <Typography className={local.colorLetra} variant="h5">
              <b>Seguridad en los datos</b>
            </Typography>
            <Typography className={local.colorLetra} variant="h5">
              Cuando tu información se guarda en la nube se generará un respaldo
              automático.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
