import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import PCMarco from "../../image/PNG de PC.png";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import cap1 from "../../image/Screens/cap1.png";
import cap2 from "../../image/Screens/cap2.png";
import cap3 from "../../image/Screens/cap3.png";
import cap4 from "../../image/Screens/cap4.png";
import cap5 from "../../image/Screens/cap5.png";
import cap6 from "../../image/Screens/cap6.png";
import cap7 from "../../image/Screens/cap7.png";
import cap8 from "../../image/Screens/cap8.png";
import cap9 from "../../image/Screens/cap9.png";
import cap10 from "../../image/Screens/cap10.png";
import cap11 from "../../image/Screens/cap11.png";
import cap12 from "../../image/Screens/cap12.png";

const images = [
  cap1,
  cap2,
  cap3,
  cap4,
  cap5,
  cap6,
  cap7,
  cap8,
  cap9,
  cap10,
  cap11,
  cap12,
];

const stylesLocal = makeStyles((theme) => ({
  containetPC: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    position: "relatives",
    marginTop: -120,
  },
  imagenPC: {
    height: "900px",
    width: "900px",
  },
  containerCarousel: {
    //border: "1px solid orange",
    width: "100%",
    height: "350px",
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    top: 150,
  },
  containerImagen: {
    //border: "1px solid blue",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    marginTop: 125,
    // background: "white",
  },
  imagen: {
    width: "900px",
    height: "900px",
  },
  carousel: {
    //border: "red solid 1px",
    height: "100%",
    width: "100%",
  },
}));

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 400, min: 0 },
    items: 1,
  },
};

export default function CarouselLg() {
  const estilos = stylesLocal();

  return (
    <Box position="relative">
      <Box className={estilos.containetPC}>
        <img className={estilos.imagenPC} src={PCMarco} alt="celularson" />
      </Box>
      <Box className={estilos.containerCarousel}>
        <Carousel
          containerClass={estilos.carousel}
          responsive={responsive}
          swipeable
          infinite
          autoPlay
          autoPlaySpeed={6000}
        >
          {images.map((img, index) => (
            <Box key={index} className={estilos.containerImagen}>
              <img
                className={estilos.imagen}
                src={img}
                alt={`scren cafi ${index}`}
              />
            </Box>
          ))}
        </Carousel>
      </Box>
    </Box>
  );
}
