import React, { useState } from "react";
/* import clienteAxios from '../../config/axios'; */
import MessageSnackbar from "../../components/Snackbar/snackbar";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import EmailIcon from "@mui/icons-material/Email";
import { Box, Grid, Link, Typography } from "@mui/material";
import Carrito from "../../image/CarritoCafi.png";
import "./contacto.css";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/system";
import { Instagram, WhatsApp } from "@mui/icons-material";

const stylesLocal = makeStyles((theme) => ({
  imagen: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  letra: {
    color: "white",
    fontWeight: "800!important",
    fontSize: "40px!important",
  },
  boton: {
    color: "#ffff",
  },
  fondo: {
    background: "#953ee4",
  },
}));

export default function Contacto() {
  const estilo = stylesLocal();
  const [snackbar, setSnackbar] = useState({
    open: false,
    mensaje: "",
    status: "",
  });

  return (
    <div>
      <MessageSnackbar
        open={snackbar.open}
        mensaje={snackbar.mensaje}
        status={snackbar.status}
        setSnackbar={setSnackbar}
      />
      <div style={{ height: 150, overflow: "hidden" }}>
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style={{ height: "100%", width: "100%" }}
        >
          <path
            d="M0.00,49.99 C154.28,294.38 373.81,-65.81 500.00,49.99 L500.00,150.00 L0.00,150.00 Z"
            style={{ stroke: "none", fill: "#953ee4" }}
          ></path>
        </svg>
      </div>
      <Grid
        container
        className={estilo.fondo}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item md={6} xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: {
                xs: 200,
                md: "100%",
              },
              width: "100%",
            }}
          >
            <img className={estilo.imagen} alt="no imagen" src={Carrito} />
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box
            id="appMovil"
            p={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Typography className={estilo.letra}>¡Contáctanos!</Typography>
          </Box>
          <Box mt={2} textAlign="center">
            <Typography sx={{ color: "white", fontSize: 30 }}>
              Teléfono: 317-383-2252
            </Typography>
          </Box>
          <Box display="flex" style={{color: "white"}} justifyContent="center" alignItems="center">
            <EmailIcon style={{ fontSize: 30, color: "white" }} />
            <Box mx={1} />
            <Link
              href={`mailto:cafipuntodeventa@gmail.com`}
              color="inherit"
              target="_blank"
              style={{textDecoration: "none"}}
            >
              <Typography sx={{ color: "white", fontSize: 30, fontWeight: 800 }}>
                cafipuntodeventa@gmail.com
              </Typography>
            </Link>
          </Box>
          <Box display="flex" justifyContent="center" textAlign="center">
            <Typography sx={{ color: "white", fontSize: 30 }}>
              Miguel Espinoza Monroy #40, CP 48900
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" textAlign="center">
            <Typography sx={{ color: "white", fontSize: 30 }}>
              Autlán de Navarro, Jalisco, México
            </Typography>
          </Box>
          <Container maxWidth="sm">
            <Box align="center" my={3}>
              <Grid container>
                <Grid item xs={6} sm={3}>
                  <Link
                    href="https://www.facebook.com/profile.php?id=100090761797800&mibextid=LQQJ4d"
                    color="inherit"
                    target="_blank"
                  >
                    <FacebookIcon
                      className={estilo.boton}
                      style={{ fontSize: 100, padding: 4 }}
                    />
                  </Link>
                </Grid>
                <Grid xs={6} sm={3}>
                  <Link
                    href="https://www.instagram.com/cafi_pv/"
                    color="inherit"
                    target="_blank"
                  >
                    <Instagram
                      className={estilo.boton}
                      style={{ fontSize: 100, padding: 4 }}
                    />
                  </Link>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Link
                    href={`https://www.youtube.com/@cafipuntodeventa`}
                    color="inherit"
                    target="_blank"
                  >
                    <YouTubeIcon
                      className={estilo.boton}
                      style={{ fontSize: 100, padding: 4 }}
                    />
                  </Link>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Link
                    href={`https://wa.me/523173832252?text=Hola, me gustaría tener más información`}
                    color="inherit"
                    target="_blank"
                  >
                    <WhatsApp
                      className={estilo.boton}
                      style={{ fontSize: 100, padding: 4 }}
                    />
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Grid>
      </Grid>
      {/* <div style={{ height: 150, overflow: "hidden" }}>
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style={{ height: "100%", width: "100%" }}
        >
          <path
            d="M-0.84,19.23 C160.55,245.22 328.72,-115.95 503.67,64.63 L500.00,0.00 L0.00,0.00 Z"
            style={{ stroke: "none", fill: "#953ee4" }}
          ></path>
        </svg>
      </div> */}
    </div>
  );
}
