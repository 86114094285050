import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";
import DoneAllIcon from "@mui/icons-material/DoneAll";

import Celular from "../../../image/Publicidad CAFI 3.1.jpg";
import useStyles from "../../estilos";
import { makeStyles } from "@mui/styles";

const styleslocales = makeStyles((theme) => ({
  containerImage: {
    width: "100%",
    height: "600px",
    [theme.breakpoints.down("sm")]: {
      height: "400px",
    },
  },
  imagen: {
    maxHeight: "100%",
    maxWidth: "100%",
    borderRadius: 20,
  },
}));

export default function Beneficios() {
  const local = styleslocales();
  const classes = useStyles();

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid
          item
          md={5}
          lg={6}
          xs={12}
          sx={{ display: "grid", placeItems: "center" }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={local.containerImage}
          >
            <img
              className={local.imagen}
              src={Celular}
              alt="Celular Promocional"
            />
          </Box>
        </Grid>
        <Grid
          item
          md={7}
          lg={6}
          xs={12}
          sx={{ display: "grid", placeItems: "center" }}
        >
          <Box>
            <Typography component="div" variant="h3" color="primary">
              <Box textAlign="left">¡Beneficios!</Box>
            </Typography>
            <Box className={classes.margenes}>
              <Divider className={classes.divisor} />
            </Box>
            <Typography component="div" variant="h6">
              <Box
                display="flex"
                alignItems="center"
                className={classes.margenes}
              >
                <Box display="flex" alignItems="center" p={1}>
                  <DoneAllIcon className={classes.colorIcon} />
                </Box>
                <Box>
                  Sistema desarrollado para llevar el control administrativo de
                  tu empresa.
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                className={classes.margenes}
              >
                <Box display="flex" alignItems="center" p={1}>
                  <DoneAllIcon className={classes.colorIcon} />
                </Box>
                <Box>Sin costo por actualizaciones.</Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                className={classes.margenes}
              >
                <Box display="flex" alignItems="center" p={1}>
                  <DoneAllIcon className={classes.colorIcon} />
                </Box>
                <Box>Interfaz fácil e intuitiva.</Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                className={classes.margenes}
              >
                <Box display="flex" alignItems="center" p={1}>
                  <DoneAllIcon className={classes.colorIcon} />
                </Box>
                <Box>Reportes en la nube y Excel.</Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                className={classes.margenes}
              >
                <Box display="flex" alignItems="center" p={1}>
                  <DoneAllIcon className={classes.colorIcon} />
                </Box>
                <Box>Sistema de puntos y promociones.</Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                className={classes.margenes}
              >
                <Box display="flex" alignItems="center" p={1}>
                  <DoneAllIcon className={classes.colorIcon} />
                </Box>
                <Box>Control de inventarios.</Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                className={classes.margenes}
              >
                <Box display="flex" alignItems="center" p={1}>
                  <DoneAllIcon className={classes.colorIcon} />
                </Box>
                <Box>
                  Capacitaciones gratis (Accede a cursos de Excel, análisis de
                  información de ventas, manejo y control de inventarios sin
                  costo).
                </Box>
              </Box>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
}
