import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Slide from "@mui/material/Slide";
import { listaCompleta } from "./ListaUsos";
import { InputAdornment, Typography } from "@mui/material";
import { Search, Storefront } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TodosLosUsos() {
  const [open, setOpen] = React.useState(false);
  const [usos, setUsos] = React.useState(listaCompleta);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = (value) => {
    const result = listaCompleta.filter((uso) =>
      uso.toLocaleLowerCase().includes(value.toLocaleLowerCase())
    );
    setUsos(result);
  };

  return (
    <div>
      <Button
        variant="contained"
        size="large"
        onClick={handleClickOpen}
        startIcon={<Storefront />}
      >
        Ver todos los giros comerciales
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle component="div">
          <Typography variant="h6">Todos los giros comerciales</Typography>
          <br />
          <TextField
            variant="outlined"
            placeholder="Buscar.."
            fullWidth
            size="small"
            onChange={(e) => handleSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </DialogTitle>
        <DialogContent sx={{height: "40vh"}}>
          <List>
            {usos.sort().map((uso, index) => (
              <ListItem key={`${uso}-${index}`} disablePadding>
                <ListItemText primary={uso} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button size="large" onClick={handleClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
