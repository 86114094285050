import React from "react"; //
import { Route, Switch } from "react-router-dom";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import Navegacion from "../Navegacion/navegacion";

export default function LayoutUsers(props) {
  const { routes } = props;

  const theme = createTheme({
    typography: {
      h6: {
        //testo
        fontSize: 18,
        fontWeight: 600,
      },
      h5: {
        //subtitulos
        fontSize: 20,
      },
      // h4:{ // titulos
      //   fontSize: 27,
      // },
      h3: {
        //nombre empresa
        fontSize: 30,
        fontWeight: 500,
      },
      body1: {
        fontSize: 16,
      },
      fontFamily: "Quicksand",
    },
    palette: {
      primary: {
        main: "#673de6",
      },
      // secondary: {
      //   main: "#160404"
      // }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div>
        <Navegacion />
        <LoadRoutes routes={routes} />
      </div>
    </ThemeProvider>
  );
}

function LoadRoutes({ routes }) {
  return (
    <Switch>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}
    </Switch>
  );
}
