//Comoponentes
import Layout from "../components/Layout_Home/Layout";

//Paginas

import Galeria from "../pages/Galeria/galeria";
import Home from "../pages/Home/Home";

const routes = [
  {
    path: "/",
    component: Layout,
    exact: false,
    routes: [
      {
        path: "/",
        component: Home,
        exact: true,
      },
      {
        path: "/galeria",
        component: Galeria,
        exact: true,
      },
    ],
  },
];

export default routes;
