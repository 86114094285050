import {
  ChairTwoTone,
  ContentCutTwoTone,
  DevicesOtherTwoTone,
  DirectionsCarTwoTone,
  HandymanOutlined,
  LocalGroceryStoreTwoTone,
  LocalMallTwoTone,
  MenuBookTwoTone,
  SmartToyTwoTone,
  SportsBarTwoTone,
} from "@mui/icons-material";

export const lista1 = [
  {
    uso: "Abarrotes",
    icon: <LocalGroceryStoreTwoTone color="primary" sx={{ fontSize: 35 }} />,
  },
  {
    uso: "Miscelánea",
    icon: <SmartToyTwoTone color="primary" sx={{ fontSize: 35 }} />,
  },
  {
    uso: "Electrónicos",
    icon: <DevicesOtherTwoTone color="primary" sx={{ fontSize: 35 }} />,
  },
  {
    uso: "Ferreterías",
    icon: <HandymanOutlined color="primary" sx={{ fontSize: 35 }} />,
  },
  {
    uso: "Licorerías",
    icon: <SportsBarTwoTone color="primary" sx={{ fontSize: 35 }} />,
  },
];

export const lista2 = [
  {
    uso: "Ropa Y Calzado",
    icon: <LocalMallTwoTone color="primary" sx={{ fontSize: 35 }} />,
  },
  {
    uso: "Librerías",
    icon: <MenuBookTwoTone color="primary" sx={{ fontSize: 35 }} />,
  },
  {
    uso: "Mueblerías",
    icon: <ChairTwoTone color="primary" sx={{ fontSize: 35 }} />,
  },
  {
    uso: "Papelerías",
    icon: <ContentCutTwoTone color="primary" sx={{ fontSize: 35 }} />,
  },
  {
    uso: "Refaccionaria",
    icon: <DirectionsCarTwoTone color="primary" sx={{ fontSize: 35 }} />,
  },
];

export const listaCompleta = [
  "Abarrotes",
  "Miscelánea",
  "Agroquímicos",
  "Fertilizantes",
  "Bisutería y joyerías",
  "Carnicerías",
  "Cremerías",
  "Distribuidoras",
  "Mayoristas",
  "Electrónicos",
  "Ferreterías",
  "Forrajerías",
  "Granos y semillas",
  "Juegueterías",
  "Didácticos",
  "Licorerías",
  "Manualidades",
  "Mercerías",
  "Ortopédicos",
  "Panaderías",
  "Pastelerías",
  "Reposterías",
  "Perfumerías",
  "Pinturas",
  "Plásticos/Desechables",
  "Sexshop",
  "Telefonía/Celulares",
  "Tiendas de Instrumentos musicales",
  "Tiendas naturistas",
  "Tiendas para mascotas",
  "Veterinaria",
  "Uniformes",
  "Accesorios",
  "Bolsas",
  "Carteras",
  "Artesanías",
  "Boutique",
  "Cosméticos",
  "Cuidado personal",
  "Departamentales",
  "Dulcería",
  "Botanas",
  "Fiestas",
  "Florerías",
  "Viveros",
  "Fruterías",
  "Verdulerías",
  "Imprentas",
  "Librerías",
  "Llanteras",
  "Materiales para construcción",
  "Mueblerías",
  "Paleterías",
  "Neverías",
  "Aguas frescas",
  "Papelerías",
  "Pescaderías",
  "Pizos y azulejos",
  "Refaccionaria",
  "Tecnología",
  "Tiendas de Deportes/Regalos",
  "Tiendas de suplementos",
  "Tiendas para Bebés",
  "Maternidad",
  "Tortillerías",
  "Zapaterías",
  "Ropa",
];
