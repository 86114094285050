import { Box, Divider, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

import ImagenOne from "../../../image/PUBLICIDAD CAFI 6.jpg";
import useStyles from "../../estilos";

const styleslocales = makeStyles((theme) => ({
  containerImage: {
    width: "100%",
    height: "600px",
    [theme.breakpoints.down("sm")]: {
      height: "300px",
    },
  },
  imagen: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
  fondo: {
    // background: "#ebebeb"
  },
}));

export default function BeneficiosSegundo() {
  const local = styleslocales();
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={11} sx={{ mt: 5 }}>
        <Typography component="div" variant="h3" color="primary">
          <Box mt={9} textAlign="center">
            ¿Qué es un punto de venta en la nube?
          </Box>
        </Typography>
        <Box p={2} mt={2}>
          <Divider className={classes.divisor} />
        </Box>
        <Typography component="div" variant="h6">
          <Box className={classes.margenes}>
            Un punto de venta en la nube es aquel que te permite controlar la
            administración de tu negocio desde un único portal web o una
            aplicación a la que puedes acceder a través de un dispositivo con
            conexión a internet, como una computadora, smartphone o tablet.
          </Box>
        </Typography>
      </Grid>
      <Grid item md={6} xs={11}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={local.containerImage}
        >
          <img
            className={local.imagen}
            src={ImagenOne}
            alt="Tecnologia de Huawei"
          />
        </Box>
      </Grid>
    </Grid>
  );
}
