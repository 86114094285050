import { Box, Divider, Grid, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import React, { Fragment } from "react";
import tienda from "../../image/TiendaRenta.png";
import tienda2 from "../../image/Tienda Renta 2.png";

import useStyles from "../estilos";
import { makeStyles } from "@mui/styles";

const stylesLocal = makeStyles((theme) => ({
  precio: {
    fontSize: 35,
    color: "#2222a8",
    textAlign: "center",
  },
  tipografia: {
    fontSize: 17,
    textAlign: "center",
    marginTop: 12,
  },
  fondo: {
    background: "#673de6",
  },
  fondoColor: {
    background: "#F8E9FF",
  },
  blanco: {
    fontSize: 35,
    textAlign: "center",
    color: "white",
  },
  fondo2: {
    background: "#F8E9FF",
  },
  containerImage: {
    width: "100%",
    height: 550,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      height: "400px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "250px",
    },
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));

export default function Paquetes() {
  const classes = useStyles();
  const estilo = stylesLocal();

  return (
    <>
      <Box id="paquetes">
        <Typography component="div" variant="h3" color="primary">
          <Box textAlign="center" mt={5}>
            Precio de Licencia
          </Box>
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          mt={2}
        >
          <Divider className={classes.divisorPrincipal} />
        </Box>
      </Box>
      <Grid container spacing={1}>
        <Grid item md={7} xs={12}>
          <Box textAlign="center" mt={7}>
            <Typography variant="h3" color="primary">
              ¡Costo Mensual!
            </Typography>
          </Box>
          <Box textAlign="center" p={1} mt={2}>
            <Typography variant="h5">
              Nos adaptamos a tu negocio, ¡Obtén CAFI Tu Punto de Venta!
            </Typography>
          </Box>
          <Box
            mt={4}
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Typography variant="h3">Por solo $500 MX</Typography>
          </Box>
          <Box p={1} mt={3} textAlign="center" alignItems="center">
            {/* <Typography variant="h5">
                                        <DoneIcon className={classes.colorIcon}/> Espacio Ilimitado
                                    </Typography> */}

            <Typography variant="h5">
              <DoneIcon className={classes.colorIcon} /> Contamos con soporte
              técnico
            </Typography>
            <Typography variant="h5">
              <DoneIcon className={classes.colorIcon} /> Sin costo por
              actualizaciones
            </Typography>
          </Box>
          <Box textAlign="center" p={2}>
            <Typography variant="h5">
              Justo lo que necesitas al alcance de tus manos. Paga ahora y obtén
              de inmediato tu licencia por un mes. ¡Crece tu negocio con CAFI!
            </Typography>
          </Box>
        </Grid>
        <Grid item md={5} xs={12}>
          <Box className={estilo.containerImage}>
            <img className={estilo.image} alt="imagen de tienda" src={tienda} />
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item md={5} xs={12}>
          <Box className={estilo.containerImage}>
            <img
              className={estilo.image}
              alt="imagen de tienda"
              src={tienda2}
            />
          </Box>
        </Grid>
        <Grid item md={7} xs={12}>
          <Box textAlign="center" mt={7}>
            <Typography variant="h3" color="primary">
              ¡Costo Anual!
            </Typography>
          </Box>
          <Box
            mt={4}
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Typography variant="h3">Por solo $3,500 MX</Typography>
          </Box>
          <Box p={1} mt={3} textAlign="center" alignItems="center">
            {/* <Typography variant="h5">
                                        <DoneIcon className={classes.colorIcon}/> Espacio Ilimitado
                                    </Typography> */}

            <Typography variant="h5">
              <DoneIcon className={classes.colorIcon} /> Asesoría oportuna ante
              cualquier duda
            </Typography>
            <Typography variant="h5">
              <DoneIcon className={classes.colorIcon} /> Ahorro al pagar un año
              completo
            </Typography>
            <Typography variant="h5">
              <DoneIcon className={classes.colorIcon} /> Control total de tu
              negocio
            </Typography>
          </Box>
          <Box textAlign="center" p={2}>
            <Typography variant="h5">
              ¡No tendrás otra oportunidad como esta! Aprovecha esta oferta
              ahora y lleva tu negocio hasta lo más alto. Paga ya y llévate la
              Licencia CAFI por un año.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
